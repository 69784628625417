import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { ReactComponent as LogoProgramarFuturo } from "../images/logo-programar-futuro-azul.svg";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="container">
        <a href=".">
          <LogoProgramarFuturo style={{ width: "250px" }} />
        </a>
        <div>
          <a
            href="https://www.facebook.com/TreeTree2-102988935638396"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF color="#060038" size={24} className="social" />
          </a>
          <a
            href="https://www.instagram.com/treetree2org/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram color="#060038" size={24} className="social" />
          </a>
        </div>
        <span className="text">
          Tens perguntas?{" "}
          <b>
            <a href="mailto:aprender@treetree2.org" className="email">
              Envia-nos um e-mail
            </a>
          </b>
        </span>
      </div>
      <div className="BottomBar">
        © treetree2 2022. todos os direitos reservados
      </div>
    </div>
  );
}
