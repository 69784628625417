import "./App.scss";
import Header from "./components/Header";
import Missao from "./components/Missao";
import Curso from "./components/Curso";
import Equipa from "./components/Equipa";
import Footer from "./components/Footer";
import Logos from "./components/Logos";

function App() {
  return (
    <>
      <Header />
      <Missao />
      <Curso />

      <img
        className="image"
        alt="eye"
        src="https://images.unsplash.com/photo-1607283339784-c73ff8195c0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80"
      />

      <Equipa />
      <Logos />
      <Footer />
    </>
  );
}

export default App;
