import { Link } from "react-scroll";
import { ReactComponent as BigArrow } from "../images/big_arrow.svg";
import { ReactComponent as LogoProgramarFuturo } from "../images/logo-programar-futuro-azul.svg";
import { useWindowSize } from "../Utils";
import SideBar from "./SideBar";

const links = [
  {
    name: "missão",
    target: "missao",
  },
  {
    name: "curso",
    target: "curso",
  },
  {
    name: "equipa",
    target: "equipa",
  },
];

export default function Header() {
  const width = useWindowSize()[0];
  let n = Math.min(Math.floor(width / 300) + 1, 5);
  return (
    <div className="Header">
      <a href=".">
        <LogoProgramarFuturo className="logo" />
      </a>
      <div className="navbar">
        {links.map((link, index) => {
          return (
            <Link
              key={index}
              to={link.target}
              spy
              smooth
              offset={-70}
              duration={500}
              className="link"
              href={"#" + link.target}
            >
              {link.name}
            </Link>
          );
        })}
      </div>
      <div className="arrows">
        {[...Array(n)].map((e, i) => (
          <BigArrow className="arrow" fill="#e82e61" key={i} />
        ))}
      </div>
      <div className="title">
        <b>o teu futuro. agora.</b>
        <p>
          vem fazer parte de um projeto que <br /> vai expandir o
          conhecimento.
        </p>
        <Link
          to={links[0].target}
          spy
          smooth
          offset={-70}
          duration={500}
          className="btn"
          href={"#" + links[0].target}
        >
          sabe mais
        </Link>
      </div>
      <SideBar title={"Scroll"} className="side" />
    </div>
  );
}
