import { ReactComponent as LogoTreeTree2 } from "../images/logo-tree.svg";

export default function Logos() {
  return (
    <div className="Logos">
      <a href="https://treetree2.org"><LogoTreeTree2 style={{width: "177px"}} /></a>
      <a href="https://bipzip.cm-lisboa.pt"><img src={require("../images/logo-bipzip.webp")} alt="Bipzip logo" /></a>
      <a href="https://ist.pt"><img src={require("../images/logo-ist.png")} alt="IST logo" /></a>
    </div>
  )
}
