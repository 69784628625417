import Accordion from "./Accordion";
import SideBar from "./SideBar";

const modules = [
  {
    title: "01. O que é a programação?",
    content: "Vamos aprender o que é um programa, uma linguagem de programação, código, e fazer o teu primeiro programa. Vamos mostrar-te onde podes escrever as instruções que queres que o computador execute - o chamado código - e como é a primeira vez, vamos começar por instruções simples, mas muito úteis, mesmo quando aprenderes mais sobre programação.",
  },
  {
    title: "02. Variáveis",
    content: "Vamos aprender o que são variáveis e para que é que servem. As variáveis são importantes porque nos permitem poupar trabalho a escrever quando fazemos a mesma coisa várias vezes, guardar coisas importantes para usar mais tarde, ou até contar quantas vezes uma coisa acontece.",
  },
  {
    title: "03. Interagir com o utilizador",
    content: "Vamos aprender como é que podemos pedir e receber informações aos utilizadores dos nossos programas. Isto é importante porque nos permite interagir com as pessoas que vão usar os nossos programas, adaptar os nossos programas às coisas que nos forem pedidas e fazer coisas diferentes com o mesmo programa.",
  },
  {
    title: "04. Comparações",
    content: "Vamos aprender como é que podemos fazer comparações em programação. Comparações são importantes porque vão permitir-nos perceber e ajustar o que os nossos programas fazem, por exemplo, a escolhas feitas por um utilizador. Isto é importante porque nos permite: perceber se a informação dada por um utilizador é o que estávamos à espera, e estabelecer uma base para definirmos as condições em que o nosso programa faz uma coisa ou outra.",
  },
  {
    title: "05. Condições",
    content: "Condições são a forma que um programa usa para decidir se faz uma coisa ou outra, dependendo das circunstâncias. Essas circunstâncias podem ser decisões que o utilizador tomou ao longo da execução do programa, ou comparações baseadas no estado do programa em si, definidas por quem o criou. Dominar bem as condições é o que faz um programa ser versátil e conseguir responder bem à utilização que lhe é dada.",
  },
  {
    title: "06. Listas",
    content: "Quando temos alguma coisa para fazer ou queremos ir às compras, é normal organizarmos as tarefas ou coisas para comprar numa lista. Nesse sentido, a lista é um conjunto ordenado de coisas, que podem ser diferentes. Em programação também há listas. Aqui, as listas são coisas parecidas com variáveis. Só que enquanto que as variáveis só podem guardar um valor, nas listas podemos guardar vários valores, separadamente, e com uma certa ordem.",
  },
  {
    title: "07. Ciclos",
    content: "Ciclos são coisas que se repetem. Por esta altura, já deves ter percebido que uma das grandes utilidades de um programa de computador é fazer muitas vezes uma coisa que, de outra forma, teríamos que fazer à mão. Com a ajuda dos ciclos, vamos perceber como é que podemos tornar automáticas várias ações que se repetem durante a execução de um programa, sem termos que ter o trabalho de as fazer de todas as vezes.",
  },
];

export default function Curso() {
  return (
    <>
      <SideBar title={"CURSO"} />
      <div className="Curso" id="curso">
        <h1>Curso de introdução à programação em Python</h1>
        <p>
        O curso tem sete módulos pré-preparados. A duração estimada de cada módulo é de 90 a 120 minutos, permitindo que o professor adapte o número de aulas por módulo conforme lhe for mais conveniente. O curso pode ser dado em formato curricular ou extracurricular e é destinado a alunos do 2º e 3º Ciclos do Ensino Básico.
        </p>
        <div className="Accordion">
          <div className="divider" />
          {modules.map((module, index) => (
            <div key={index}>
              <Accordion
                title={module.title}
                content={module.content}
              />
              <div className="divider" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
