import SideBar from "./SideBar";
import Slides from "./Slides";
import { ReactComponent as SmallArrow } from "../images/small_arrow.svg";
import { ReactComponent as BigArrow } from "../images/big_arrow.svg";
import { useWindowSize } from "../Utils";

export default function Missao() {
  const width = useWindowSize()[0];
  let n1 = Math.floor((width * 0.76) / 37);
  let n2 = Math.min(Math.floor((width * 0.9) / 273) + 1, 5);
  return (
    <>
      <SideBar title={"MISSÃO"} />
      <div className="Missao" id="missao">
        <h1>
        Aprender a programar é aprender a pensar. E agora, qualquer Escola pode ensinar a programar.
        </h1>

        <p>
        Usando um curso com sete módulos pré-preparados em português - que inclui vídeos, exemplos de código, exercícios, quizzes, e um projeto final - os professores podem orientar a aprendizagem de quaisquer alunos que queiram aprender a programar, mesmo que nunca tenham programado antes.
        </p>

        <div className="video">
          <div className="player">
            <lite-youtube
              videoid="xG_vup9c5wE"
              videotitle="Programar o Futuro"
            ></lite-youtube>
          </div>
          <h1>
            O Agrupamento de Escolas Lindley Cintra em Lisboa já começou a programar. E o teu?
          </h1>
          <div className="arrows">
            {[...Array(n1)].map((e, i) => (
              <SmallArrow className="arrow" fill="#e82e61" key={i} />
            ))}
          </div>
        </div>
      </div>
      <div className="Missao-2">
        <img
          src="/destaque-3.webp"
          alt="Programar o Futuro"
        />
        <div className="text">
          <h1>Como começar a Programar o Futuro na minha Escola?</h1>
          <p>
          São só precisos três passos:
          <ol>
            <li>Enviar um email para <a href="mailto:aprender@treetree2.org" className="email">aprender@treetree2.org</a>.</li>
            <li>Arranjar um espaço e um horário na escola.</li>
            <li>Usar as nossas aulas pré-preparadas.</li>
          </ol>    
          </p>
        </div>
      </div>
      <Slides />
      <div className="bigArrows">
        {[...Array(n2)].map((e, i) => (
          <BigArrow className="bigArrow" fill="#e82e61" key={i} />
        ))}
      </div>
    </>
  );
}
